import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly element: HTMLElement;
  static targets = ['message'];
  
  connect() {
    console.log('[login form] connect');

    if (this.hasMessageTarget) {
      this.messageTarget.style.display = 'none';
    }
  }

  send(e) {
    console.log('[login form] send');
    e.preventDefault();
    this.messageTarget.style.display = 'flex';
  }
  success(e) {
    console.log('[login form] success');
    e.preventDefault();
    this.messageTarget.style.display = 'flex';
  }
  error(e) {
    console.log('[login form] error');
    e.preventDefault();
    this.messageTarget.style.display = 'flex';
  }

  disconnect() {
    if (this.hasMessageTarget) {
      this.messageTarget.style.display = 'none';
    }
  }
}


export default ComponentController;
