import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly pictureTarget: HTMLElement;
  static targets = [];
  
  connect() {
    const Chart = chartjs_3_9_1;

    if (!Chart) {
      console.error('Chart package not found.');
      
      return;
    }

    const data = JSON.parse(this.element.dataset.chartData || '{}');
    const chartType = this.element.dataset.chartType || 'line';

    const canvas = document.createElement('canvas');
    canvas.width = '420';
    canvas.height = '280';
    canvas.style.width = '420px';
    canvas.style.height = '280px';
    canvas.style.maxHeight = '280px';
    this.element.appendChild(canvas);

    // const data = {
    //   labels: ['1', '2', '3', '4', '5', '6', '7'],
    //   datasets: [{
    //     label: 'My First Dataset',
    //     data: [65, 59, 80, 81, 56, 55, 40],
    //     fill: false,
    //     borderColor: 'rgb(81, 93, 225)',
    //     tension: 0.1
    //   }]
    // };
    const config = {
      type: chartType,
      data: data,
      responsive: false,
      maintainAspectRatio: false,
      options: {
        scales: {
          y: {
            grid: {
              color: 'white'
            }
          },
          x: {
            grid: {
              color: 'white'
            }
          }
        }
      }
    };

    const myChart = new Chart(canvas, config);
    
    console.log('Chart connected.');
  }

  disconnect() {
    this.element.innerHTML = '';
  }
}


export default ComponentController;
