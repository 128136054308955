import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly pictureTarget: HTMLElement;
  static targets = ['mobileMenu'];
  
  connect() {
    console.log('Menu connected.');
    this.close();
  }

  open() {
    if (this.hasMobileMenuTarget) {
      this.mobileMenuTarget.style.display = 'flex';
      this.mobileMenuTarget.style.opacity = '1';
      this.mobileMenuTarget.style.visibility = 'visible';
    }
    
    document.body.style.overflow = 'hidden';
  }

  close() {
    if (this.hasMobileMenuTarget) {
      this.mobileMenuTarget.style.display = 'none';
      this.mobileMenuTarget.style.opacity = '0';
      this.mobileMenuTarget.style.visibility = 'hidden';
    }
    
    document.body.style.overflow = null;
  }

  disconnect() {
    this.close();
  }
}


export default ComponentController;
