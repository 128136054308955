import c0 from './chartjs_controller';
import c1 from './form_controller';
import c2 from './login_form_controller';
import c3 from './menu_controller';
import c4 from './product_form_controller';
import c5 from './project_user_resource_form_controller';
import c6 from './project_user_signin_form_controller';
import c7 from './text_typing_effect_controller';
import c8 from './wizard_form_controller';
export const definitions = [
	{identifier: 'chartjs', controllerConstructor: c0},
	{identifier: 'form', controllerConstructor: c1},
	{identifier: 'login-form', controllerConstructor: c2},
	{identifier: 'menu', controllerConstructor: c3},
	{identifier: 'product-form', controllerConstructor: c4},
	{identifier: 'project-user-resource-form', controllerConstructor: c5},
	{identifier: 'project-user-signin-form', controllerConstructor: c6},
	{identifier: 'text-typing-effect', controllerConstructor: c7},
	{identifier: 'wizard-form', controllerConstructor: c8},
];
