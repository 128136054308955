import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly element: HTMLElement;
  static targets = ['textContainer'];

  connect() {
    this.activeContainer = this.textContainerTargets.find(
      (el) => {
        return el.dataset['text-fade-gallery-active'] !== undefined;
      }
    );
    this.activeContainer ||= this.textContainerTargets[0];
    this.activeContainer.style.display = 'block';
    this.index = this.textContainerTargets.indexOf(this.activeContainer);

    console.log('status:', this.status);

    this.step();
  }

  step() {
    if (this.status === 'erasing') {
      this.timeout = setTimeout(() => {
        if (this.currentTextLength > 0) {
          const text = this.activeContainer.innerText;
          this.currentTextLength = this.currentTextLength - 1;
          if (this.currentTextLength > 0) {
            this.activeContainer.innerText = text.substr(0, this.currentTextLength);
          } else {
            this.activeContainer.innerHTML = "&nbsp;";
          }
        } else {
          let nextIndex = this.index + 1;
          if (this.textContainerTargets.length <= nextIndex) {
            nextIndex = 0;
          }
          const currentTextContainer = this.textContainerTargets[this.index];
          const nextTextContainer = this.textContainerTargets[nextIndex];

          nextTextContainer.setAttribute('data-text-typing-effect-active', '');
          currentTextContainer.removeAttribute('data-text-typing-effect-active');

          currentTextContainer.innerText = this.element.dataset.textWas;
          this.element.dataset.textWas = nextTextContainer.innerText.trim();
          nextTextContainer.innerHTML = "&nbsp;";
          nextTextContainer.style.display = 'block';
          currentTextContainer.style.display = 'none';
          this.status = 'writing';

          this.activeContainer = nextTextContainer;
          this.index = nextIndex;
        }
        this.step();
      }, 20);
    } else if (this.status === 'writing') {
      this.timeout = setTimeout(() => {
        const text = this.element.dataset.textWas;
        this.currentTextLength += 1;
        if (this.currentTextLength <= text.length) {
          this.activeContainer.innerText = text.substr(0, this.currentTextLength);
        } else {
          this.status = null;
        }
        this.step();
      }, Math.random() * 30 + 20);
    } else {
      this.timeout = setTimeout(() => {
        this.element.dataset.textWas = this.activeContainer.innerText.trim();
        this.currentTextLength = this.element.dataset.textWas.length;
        this.status = 'erasing';
        this.step();
      }, 3000);
    }
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    if (this.element.dataset.textWas) {
      this.activeContainer.innerText = this.element.dataset.textWas;
    }
    
    this.status = null;

    this.activeContainer.removeAttribute('data-text-typing-effect-active');
    this.activeContainer.style.display = 'none';

    this.element.removeAttribute('data-text-was');
  }
}


export default ComponentController;
