import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly element: HTMLElement;
  static targets = ['step'];

  initialize() {
    console.log('[wizard form] initialize');
  }
  
  connect() {
    console.log('[wizard form] connect');
    this.currentStep = Number(this.data.get('currentStep')) || 0;
    this.renderSteps();
  }

  prevStep() {
    this.currentStep -= 1;
    this.renderSteps();
  }

  nextStep() {
    this.currentStep += 1;
    this.renderSteps();
  }

  renderSteps() {
    this.stepTargets.forEach((step) => {
      const stepNumber = step.dataset.step;
      if (stepNumber && Number(stepNumber) === this.currentStep) {
        step.style.display = 'flex';
      } else {
        step.style.display = 'none';
      }
    });
  }

  disconnect() {
    this.data.set('currentStep', this.currentStep);
  }
}


export default ComponentController;
